import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { InputGroup } from 'react-bootstrap';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import Form from 'react-bootstrap/Form';
import calendarIcon from '../../assets/img/calendar.svg';
import CloseButton from '../../assets/img/close-icon.svg';
import { toLocalDate, toServerDate } from '../Utils/Helper';

const CustomDateRangePicker = (props) => {
  const {
    startDate,
    endDate,
    isInvalid,
    disabled,
    onApply,
    className = '',
    controlId,
    placeholder,
    inputClass,
    minDate,
    maxDate,
    errorMessage,
    inputProps,
    ...restProps
  } = props;
  const { initialSettings } = restProps;
  const format = initialSettings.locale?.format || 'DD-MMM-YYYY';

  let displayValue = startDate
    ? `${toLocalDate(startDate, format)} to ${toLocalDate(endDate, format)}`
    : '';
  displayValue = initialSettings.singleDatePicker ? toLocalDate(startDate, format) : displayValue;
  function onDateSelect($event, picker) {
    if (disabled) {
      return;
    }
    const { startDate: pickerStartDate, endDate: pickerEndDate } = picker;
    const date = {
      startDate: toServerDate(pickerStartDate),
      endDate: toServerDate(pickerEndDate),
    };
    onApply(date, $event);
  }

  moment(startDate)?._isValid
    ? (initialSettings.startDate = moment(startDate))
    : (initialSettings.startDate = new Date());

  const ranges = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'This Week': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Week': [
      moment().subtract(1, 'week').startOf('isoWeek'),
      moment().subtract(1, 'week').endOf('isoWeek'),
    ],
    'Last Month': [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
  };

  return (
    <DateRangePicker
      onApply={onDateSelect}
      minDate={minDate}
      maxDate={maxDate}
      ranges={ranges}
      showDropdowns={true}
      singleDatePicker={false}
      {...restProps}
      opens="left">
      <InputGroup>
        <Form.Control
          style={{
            height: '28px',
            borderRadius: '6px',
            border: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
          isInvalid={isInvalid}
          placeholder={placeholder || 'Select Dates'}
          disabled={disabled}
          autoComplete="off"
          className={`datepicker-field form-control-one ${inputClass}`}
          onChange={() => {}}
          value={displayValue}
          {...inputProps}
        />
        <InputGroup.Text
          style={{ height: '28px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          className="column-filter-input-icon input-group-text">
          {displayValue && (
            <img
              src={CloseButton}
              width="10px"
              height="10px"
              alt="close"
              className="mr-3"
              style={{ cursor: 'pointer' }}
              onClick={() => props.setFilter(undefined)}
            />
          )}
          <img src={calendarIcon} width="15px" height="15px" alt="search" />
        </InputGroup.Text>
      </InputGroup>
    </DateRangePicker>
  );
};

CustomDateRangePicker.propTypes = {
  errorMessage: PropTypes.string,
  inputClass: PropTypes.string,
  isInvalid: PropTypes.bool,
  controlId: PropTypes.string,
};

export default CustomDateRangePicker;

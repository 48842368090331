import { Paper, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as LoginTruckIcon } from '../../../assets/img/_App/login_truck.svg';
import { useRequestOTP } from '../../../hooks/hooks';
import FormField from '../../../shared/Components/_App/FormField';
import { hasError } from '../../../shared/Utils/Helper';
import { getAccount } from '../../../state/Account/accountThunks';

const LoginScreen = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [mobileView, setMobileView] = useState(true);
  const [, { doRequestOTP, doVerifyOTP }] = useRequestOTP();

  const handleLogin = async () => {
    if (mobileNumber?.match('^[0-9]{10}$') === null) {
      toast.error('Invalid Number');
      return;
    } else {
      const response = await doRequestOTP({ mobile: mobileNumber });

      if (isEmpty(response)) {
        setMobileView(false);
      }
    }
  };

  const handleResendOtp = async () => {
    const response = await doRequestOTP({ mobile: mobileNumber });
    const error = hasError(response);
    if (error) {
      return;
    }
    if (isEmpty(response)) {
      toast.success('OTP has been sent successfully to the mobile number');
    }
  };

  const handleOTP = async () => {
    if (isEmpty(otp)) {
      toast.error('Enter Otp');
      return;
    }
    const response = await doVerifyOTP({
      mobile: mobileNumber,
      verificationCode: otp,
      deliveryId: props?.match?.params?.id,
    });
    const error = hasError(response);
    if (error) {
      toast.error(error);
      return;
    }
    if (!isEmpty(response)) {
      localStorage.setItem('authenticate_token', response?.id_token);
      dispatch(getAccount());
      history.push(`/supervisor/deliveries/${props?.match?.params?.id}`, { mobile: mobileNumber });
    }
  };

  return (
    <div style={{ overflow: 'hidden', backgroundColor: 'white', height: '100%' }}>
      <Paper
        elevation={0}
        style={{
          marginBottom: '40px',
          backgroundColor: '#f4f6fc',
          height: '30%',
        }}>
        <LoginTruckIcon style={{ marginLeft: '-20px' }} />
        <div style={{ position: 'absolute', lineHeight: 1, top: '5em', paddingLeft: '20px' }}>
          {mobileView ? (
            <>
              <Typography style={{ marginTop: '20px' }} variant="subtitle1">
                Sign in to Recykal
              </Typography>
              <Typography variant="h5"> Enter your phone number to continue</Typography>
            </>
          ) : (
            <>
              <Typography variant="subtitle1" style={{ marginTop: '20px' }}>
                Verify OTP
              </Typography>
              <Typography variant="h5">Verify with OTP sent to +91 {mobileNumber}</Typography>
            </>
          )}
        </div>
      </Paper>

      {mobileView ? (
        <FormField
          FormControlClassName="pl-3 pr-3"
          label="MOBILE NUMBER"
          value={mobileNumber}
          isAutoComplete={true}
          onChange={(e) => {
            setMobileNumber(e.target.value);
            setOtp('');
          }}
        />
      ) : (
        <>
          <div className="pl-3 pr-3">
            <Typography
              onClick={() => handleResendOtp()}
              className="p-0 float-right"
              color="textSecondary"
              variant="body1">
              Resend Otp
            </Typography>
            <Typography className="p-0" color="textSecondary" variant="body1">
              OTP
            </Typography>
          </div>
          <FormField
            FormControlClassName="pl-3 pr-3"
            isAutoComplete={true}
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
        </>
      )}

      <Button
        style={{ marginLeft: '16px', marginRight: '15px', width: '90%' }}
        onClick={() => (mobileView ? handleLogin() : handleOTP())}>
        {mobileView ? 'Login' : 'Verify Otp'}
      </Button>
    </div>
  );
};

export default LoginScreen;

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider } from '@material-ui/core';
import { flatMap, uniq } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import EditIconBlue from '../../../assets/imageComponents/EditIconBlue';
import { DeliveryService } from '../../../features/Delivery/delivery.service';
import ApproveWorkOrderModal from '../../../features/WorkOrder/ApproveWorkOrderModal';
import RejectWorkOrderModal from '../../../features/WorkOrder/RejectWorkOrderModal';
import {
  EDITABLE_BRAND_WO_STATUSES,
  WORK_ORDER_TABLE_COLUMNS,
  WORK_ORDER_UTILS,
} from '../../../features/WorkOrder/WokrOrderConstants';
import WorkOrderItems from '../../../features/WorkOrder/WorkOrderItems/WorkOrderItems';
import CommentsModal from '../../../features/WorkOrder/WorkOrderItemsEdit/CommentsModal';
import PromptInputModal from '../../../features/WorkOrder/WorkOrderItemsEdit/PromptInputModal';
import { WorkOrderService } from '../../../features/WorkOrder/workOrder.service';
import { useAccount, useAsyncEffect, useMaterialCategory, useRegions } from '../../../hooks';
import { useClients } from '../../../hooks/useCustomers';
import { useBrowseNodeMap } from '../../../hooks/useMaterialCategory';
import HasAuthority, {
  useAuthority,
} from '../../../shared/Components/HasAuthority/HasAuthorityNew';
import Loader from '../../../shared/Components/Loader/Loader';
import PromptModal from '../../../shared/Components/PromptModal/PromptModal';
import MultiUploadCard from '../../../shared/Components/UploadScrollableCard/MultiUploadCardNew';
import { AUTHORITY2, WORK_ORDER_DISPLAY_STATUS } from '../../../shared/Constants/Constants';
import { toFinYear, toLocalDate } from '../../../shared/Utils/Helper';
import { AccountSelectors } from '../../../state/Account';
import DocumentModal from '../DocumentModal';
import WorkOrderChangeLog from './WorkOrderChangeLog';
import './WorkOrderDetails.scss';

const BrandWorkOrderDetails = (props) => {
  const history = useHistory();
  const { hasAuth } = useAuthority();
  const { itemsById } = useBrowseNodeMap();
  const isPRO = hasAuth([AUTHORITY2.PRO]);
  const isBrand = hasAuth([AUTHORITY2.BRAND]);
  const isWorkOrderOutgoing = isBrand;
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [workOrder, setWorkOrder] = useState({
    documents: [],
    workOrderItems: [],
  });

  const [showModal, setShowModal] = useState(false);
  const { brands } = useSelector(({ account }) => {
    return { brands: account.clients };
  });
  const { hasPermission } = useAuthority();
  const canAudit =
    isBrand ||
    (isPRO &&
      hasPermission({
        permissionsAllowed: [
          {
            groupName: 'Execute_Targets',
            moduleName: 'Work_Orders',
            name: 'audit_trail',
          },
        ],
      }));

  const canCloseWO =
    (isPRO &&
      hasPermission({
        permissionsAllowed: [
          {
            groupName: 'Execute_Targets',
            moduleName: 'Work_Orders',
            name: 'close_work_order',
          },
        ],
      })) ||
    (isBrand &&
      hasPermission({
        permissionsAllowed: [
          {
            groupName: 'Manage_Vendors',
            moduleName: 'Work_Orders',
            name: 'close_work_order',
          },
        ],
      }));

  const canEditWO =
    (isBrand ||
      (isPRO &&
        hasPermission({
          permissionsAllowed: [
            {
              groupName: 'Execute_Targets',
              moduleName: 'Work_Orders',
              name: 'can_edit',
            },
          ],
        }))) &&
    (!['IN_PROGRESS'].includes(workOrder.status) ||
      workOrder?.workOrderItems?.some((li) => li.fulfilledQty < li.qty));
  const canDeleteWO =
    isBrand ||
    (isPRO &&
      hasPermission({
        permissionsAllowed: [
          {
            groupName: 'Execute_Targets',
            moduleName: 'Work_Orders',
            name: 'can_delete',
          },
        ],
      }));
  const canApproveRejectWO =
    isPRO &&
    hasPermission({
      permissionsAllowed: [
        {
          groupName: 'Execute_Targets',
          moduleName: 'Work_Orders',
          name: 'approve_reject_wo',
        },
      ],
    });
  const canViewWO =
    isBrand ||
    (isPRO &&
      hasPermission({
        permissionsAllowed: [
          {
            groupName: 'Execute_Targets',
            moduleName: 'Work_Orders',
            name: 'can_view',
          },
        ],
      }));
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [displayDocumentModal, setDisplayDocumentModal] = useState(false);
  const [, setDeleteAllowed] = useState(true);
  const [closeWoModal, setCloseWoModal] = useState(false);
  const handleCloseWoModal = (v) => {
    setCloseWoModal(v);
    setClosewomodaltext('');
  };
  const [openWoModal, setOpenWoModal] = useState(false);
  const [closewomodaltext, setClosewomodaltext] = useState('');
  const submitclosewodetails = () => {
    setCloseWoModal(false);
    setClosewomodaltext('');
    doOpenCloseWorkOrders('CLOSE');
  };

  const [logs, setLogs] = React.useState();
  const [showHistory, setShowHistory] = React.useState();
  const [showRejectModal, setShowRejectModal] = useState();
  const [rejectionComments, setRejectionComments] = useState();
  const [showApproveModal, setShowApproveModal] = useState();
  const [approvalComments, setApprovalComments] = useState();
  const [loading, setLoading] = useState();
  const [specialModal, setSpecialModal] = useState(false);
  const [specialData, setSpecialData] = useState('');

  const region = useRegions();
  const browseNodes = useMaterialCategory();
  const account = useAccount();
  const clients = useClients(true);
  const isUserServiceProvider = AccountSelectors.isUserServiceProvider(account);
  const workOrderId = props.match.params.id;
  const isUserRapidue = account.user?.profile?.id === 8651;
  const [specialKey, setSpecialKey] = useState(false);
  const canEditInProgress =
    hasPermission({
      permissionsAllowed: [
        {
          groupName: 'Execute_Targets',
          moduleName: 'Work_Orders',
          name: 'in_progress',
        },
      ],
    }) &&
    (!['IN_PROGRESS'].includes(workOrder.status) ||
      workOrder?.workOrderItems?.some((li) => li.fulfilledQty < li.qty));

  useEffect(() => {
    const WoCombinedCategory = workOrder?.workOrderItems?.every((workOrderItem) =>
      ['1', '2', '4', '6']?.includes(workOrderItem?.browseNodeId)
    )
      ? 'plastic'
      : workOrder?.workOrderItems?.every((workOrderItem) => workOrderItem?.browseNodeId === '3')
      ? 'Ewaste'
      : undefined;
    const brandList =
      WoCombinedCategory === 'plastic'
        ? uniq(
            flatMap(
              workOrder?.workOrderItems?.map((workOrderItem) => {
                return workOrderItem?.associatedBrands?.map(
                  (associatedBrandId) =>
                    brands.find((brand) => brand?.id === associatedBrandId)?.name
                );
              })
            )
          )
        : [brands.find((brand) => brand?.id === workOrder?.brandId)?.name];
  }, [workOrder, brands]);

  useAsyncEffect(async () => {
    getWorkOrdersItems();
    try {
      const response = await DeliveryService.getUsersDetails();
      if (response.status === 200) {
        setUsers(response?.data);
      }
    } catch (error) {
      console.log('error', error);
    }
  }, [props.location.search]);

  const doDeleteWorkOrder = async () => {
    setShowDeleteConfirmModal(false);
    try {
      await WorkOrderService.deleteWorkOrder(workOrder?.id);
      toast.success('Work order deleted successfully.');
      history.goBack();
    } catch (e) {}
  };

  const getWorkOrdersItems = async () => {
    try {
      const response = await WorkOrderService.fetchWorkOrderById(
        workOrderId,
        decodeURIComponent(props?.location?.search)
      );
      setSpecialKey(response?.data?.specialApproval);

      if (response.data) {
        const workOrderData = { ...response?.data };
        if (workOrderData?.workOrderItems.length) {
          workOrderData.workOrderItems.forEach((workOrderItem) => {
            if (workOrderItem.fulfilledQty !== null && workOrderItem?.fulfilledQty > 0) {
              setDeleteAllowed(false);
              return;
            }
          });
        }
        setWorkOrder({
          ...workOrderData,
          workOrderItems: workOrderData?.workOrderItems?.map((wo_item) => ({
            ...wo_item,
            stateCode: wo_item?.stateCode !== null ? wo_item?.stateCode : 0,
            stateId: wo_item?.stateId !== null ? wo_item?.stateId : 0,
          })),
        });
      }

      const logData = (await WorkOrderService.fetchWorkOrderLogs(workOrderId)).data;
      setLogs(logData);
    } catch (e) {}
    setIsLoading(false);
  };

  const handlesaveEditedDetails = async () => {
    setSpecialModal((pre) => !pre);
    // setSpecialData('');
    let finalStatus =
      workOrder?.status === 'IN_REVIEW'
        ? 'IN_COMPLETE'
        : workOrder?.status === 'DRAFT' || workOrder?.status === 'REJECTED'
        ? 'IN_COMPLETE'
        : workOrder?.status;
    if (workOrder?.status === 'DRAFT') {
      workOrder.draft = false;
    }
    const approveResp = await WorkOrderService.updateWorkOrder({
      ...workOrder,
      specialApprovalRemark: specialData,
      status: finalStatus,
      specialApproval: true,
      workOrderItems: workOrder.workOrderItems.map((workOrderItem) => ({
        ...workOrderItem,
        taxRate: itemsById[workOrderItem?.itemId]?.hsnCode?.tax,
        hsnCode: itemsById[workOrderItem?.itemId]?.hsnCode?.id,
      })),
    });
    if (approveResp.data.status === 'IN_COMPLETE') {
      toast.success('Work Order Moved to Incomplete status');
    }
    setSpecialData('');
    getWorkOrdersItems();
  };

  // const submitData = () => {
  //   prepareWorkOrderData({
  //     ...workOrderForm.values,
  //     remarks: SaveEditText ? SaveEditText : '',
  //     draft: false,
  //     status: isProgress ? 'IN_PROGRESS' : hasPlastic ? 'IN_REVIEW' : 'IN_PROGRESS',
  //   });
  // };

  const ModalsavedEditedDetails = () => {
    handlesaveEditedDetails();
    // submitData();
  };

  const fetchDeliveries = async ({
    row: {
      values: { itemId, stateId, districtId },
      index,
    },
  }) => {
    const { deliveries } = await DeliveryService.fetchDeliveries({
      direction: isWorkOrderOutgoing ? 'RECEIVED' : 'ASSIGNED',
      ids: workOrder?.id,
      itemIds: itemId,
      stateIds: stateId,
      districtIds: districtId,
      size: 100,
    });
    if (deliveries?.received?.length || deliveries?.assigned?.length) {
      const orderItems = [...workOrder.workOrderItems];
      orderItems[index].subRows = WORK_ORDER_UTILS.transformDeliveryDataToWorkOrderItem(
        isWorkOrderOutgoing ? deliveries?.received : deliveries?.assigned
      );
      setWorkOrder({
        ...workOrder,
        workOrderItems: orderItems,
      });
    }
  };

  const docAttach = async (documentReqData) => {
    const response = await WorkOrderService.uploadDocuments(documentReqData);
    if (response?.data) {
      let existingValue = workOrder.documents || [];
      existingValue.push(response?.data);
      setWorkOrder((prevOrder = {}) => {
        return {
          ...prevOrder,
          documents: existingValue,
        };
      });
    }
  };

  const handleDocChange = (uploadDocList) => {
    uploadDocList.forEach((newData) => {
      newData.entityId = workOrderId;
      docAttach(newData);
    });
  };

  const doDeleteDocuments = async (delDoc) => {
    if (delDoc.id) {
      try {
        await WorkOrderService.deleteWorkOrderDocument(delDoc.id);
        setDeletedDocToLocal(delDoc);
      } catch (e) {}
    } else {
      setDeletedDocToLocal(delDoc);
    }
  };

  const setDeletedDocToLocal = (delDoc) => {
    let index = null;
    const { documents } = { ...workOrder };
    if (!!delDoc.id) {
      index = documents?.findIndex((e) => e.id === delDoc.id);
    } else {
      index = documents?.findIndex((e) => e.fileName === delDoc.fileName);
    }
    documents.splice(index, 1);
    setWorkOrder({ ...workOrder, documents: [...documents] });
  };

  const doOpenCloseWorkOrders = async (type) => {
    try {
      if (type === 'CLOSE') {
        const closeWoRes = await WorkOrderService.closeWorkOrders([
          { remarks: closewomodaltext ? closewomodaltext : '', id: workOrderId },
        ]);
        if (closeWoRes.status === 200) {
          setCloseWoModal(false);
          setClosewomodaltext('');
          toast.success('Work Order Closed successfully');
          getWorkOrdersItems();
        }
      } else {
        const openWoRes = await WorkOrderService.openWorkOrder(workOrderId);
        if (openWoRes.status === 200) {
          setOpenWoModal(false);
          getWorkOrdersItems();
          toast.success('Work order opened successfully');
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const isInReview = workOrder?.status === 'IN_REVIEW';
  const isRejected = workOrder?.status === 'REJECTED';

  const approveRejectHandler = async (status) => {
    setLoading(true);
    const commentObj =
      status === 'REJECTED'
        ? {
            rejectionComments: rejectionComments,
            remarks: rejectionComments ? rejectionComments : '',
          }
        : status === 'IN_PROGRESS'
        ? {
            approvalComments: approvalComments,
            remarks: approvalComments ?? '',
          }
        : {};
    // let finalStatus =
    //   status === 'REJECTED' ? 'IN_COMPLETE' : status === ('IN_REVIEW') ? 'REJECTED' : status;

    let finalStatus = status;

    try {
      const approveResp = await WorkOrderService.updateWorkOrder({
        ...workOrder,
        status: finalStatus,
        ...commentObj,
        workOrderItems: workOrder.workOrderItems.map((workOrderItem) => ({
          ...workOrderItem,
          taxRate: itemsById[workOrderItem?.itemId]?.hsnCode?.tax,
          hsnCode: itemsById[workOrderItem?.itemId]?.hsnCode?.id,
        })),
      });
      if (status === 'IN_PROGRESS') {
        toast.success('Work order approved');
        setShowApproveModal(false);
        setApprovalComments();
      }
      if (status === 'REJECTED') {
        toast.success('Work order rejected');
        setShowRejectModal(false);
        setRejectionComments();
      }

      if (status === 'IN_COMPLETE' || status === 'IN_REVIEW') {
        toast.success(`work order moved to  ${finalStatus}`);
        setShowRejectModal(false);
        setRejectionComments();
      }
      getWorkOrdersItems();
    } catch (err) {}
    setLoading(false);
  };

  const hadnleRejectModal = () => {
    setShowRejectModal((prev) => !prev);
  };

  const handleApproveModal = () => {
    setShowApproveModal((prev) => !prev);
  };

  const handleClose = () => setShowModal(false);

  const disableSpecialApproval = () => {
    return workOrder.workOrderItems.some((item) => !item.qty || !item.pricePerUnit);
  };

  return (
    <div className={'animated fadeIn BrandWorkOrderDetails filter-div'}>
      {isLoading ? (
        <Loader className="page-loading" />
      ) : (
        <React.Suspense fallback={<Loader className="app-loading" />}>
          <Card className="main-card ">
            <Form onSubmit={(e) => {}}>
              <Card.Header className="bg-white border-0  py-0">
                <Row className="padding-aligned align-self-center">
                  <Col className="d-flex align-items-center" xs={4}>
                    <Button className={'mr-3 back-btn'} onClick={() => history.goBack()}>
                      <FontAwesomeIcon className={''} icon={faChevronLeft} />
                    </Button>
                    <div
                      // className="d-flex-column"
                      style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                      <span className="wotitleflex">
                        <h4> {workOrderId}</h4>
                        <Badge
                          style={{
                            backgroundColor: WORK_ORDER_DISPLAY_STATUS?.[workOrder?.status]?.bg,
                            color: WORK_ORDER_DISPLAY_STATUS?.[workOrder?.status]?.textColor,
                            border: `1px solid ${
                              WORK_ORDER_DISPLAY_STATUS?.[workOrder?.status]?.borderColor
                            }`,
                            borderRadius: '16px',
                            padding: '2px 8px',
                            fontSize: '12px',
                            lineHeight: '18px',
                            fontWeight: 500,
                            textAlign: 'center',
                            fontFamily: 'Inter',
                          }}>
                          {WORK_ORDER_DISPLAY_STATUS?.[workOrder?.status]?.displayText}
                        </Badge>
                      </span>
                      {canApproveRejectWO && (
                        <div className="d-flex justify-content-between">
                          <span>BDM: {workOrder?.bdmName ?? 'NA'} </span>
                          <Divider orientation="vertical" flexItem />
                          <span>Zone: {workOrder?.bdmZone?.name ?? 'NA'}</span>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col className="d-flex buttons justify-content-end">
                    <HasAuthority authorities={[AUTHORITY2.BRAND, AUTHORITY2.PRO]}>
                      {workOrder?.status === 'IN_PROGRESS' && (
                        <Button
                          className="fulfilment_plan_button mr-3"
                          variant="outline"
                          onClick={() => history.push(`/work-order/fulfilmentplan/${workOrderId}`)}>
                          FulFilment Plan
                        </Button>
                      )}
                    </HasAuthority>
                    {canAudit && isUserRapidue && (
                      <Button
                        variant="outline"
                        className={'audit_trail_button float-right mr-3'}
                        onClick={() => setShowHistory(!showHistory)}>
                        Audit Trail
                      </Button>
                    )}
                    {/* {(workOrder?.status === 'DRAFT' ||
                      workOrder?.status === 'IN_REVIEW' ||
                      (workOrder?.status === 'REJECTED')) && (
                      <Button
                        variant="outline"
                        className="audit_trail_button float-right mr-3"
                        onClick={() => setSpecialModal(true)}>
                        Special Approval
                      </Button>
                    )} */}

                    <HasAuthority
                      authorities={[AUTHORITY2.PRO]}
                      permissionsAllowed={[
                        {
                          name: 'special_approval',
                          moduleName: 'Work Orders',
                          groupName: 'Execute Targets',
                        },
                      ]}>
                      {(workOrder?.status === 'DRAFT' ||
                        workOrder?.status === 'IN_REVIEW' ||
                        workOrder?.status === 'REJECTED') && (
                        <Button
                          variant="outline"
                          className="audit_trail_button float-right mr-3"
                          onClick={() => setSpecialModal(true)}
                          disabled={disableSpecialApproval()}>
                          Special Approval
                        </Button>
                      )}
                    </HasAuthority>

                    {canCloseWO && ['IN_PROGRESS'].includes(workOrder.status) && (
                      <Button
                        variant="outline"
                        className="close_wo_button mr-3"
                        onClick={() => handleCloseWoModal(true)}>
                        Close WO
                      </Button>
                    )}

                    {canEditWO && EDITABLE_BRAND_WO_STATUSES?.includes(workOrder.status) && (
                      <Button
                        className={'edit_btn_brand mr-3'}
                        onClick={() =>
                          history.push('/work-orders-brandPro/edit/' + workOrderId + '?isEdit=true')
                        }
                        variant={'outline'}>
                        <EditIconBlue strokeColor="#155EEF" /> Edit
                      </Button>
                    )}

                    {canEditInProgress && workOrder?.status === 'IN_PROGRESS' && (
                      <Button
                        className={'edit_btn_brand mr-3'}
                        onClick={() =>
                          history.push('/work-orders-brandPro/edit/' + workOrderId + '?isEdit=true')
                        }
                        variant={'outline'}>
                        <EditIconBlue strokeColor="#155EEF" /> Edit
                      </Button>
                    )}
                    {workOrder.draft && canDeleteWO && (
                      <Button
                        // className={'save-btn  text-dark font-regular ml-3'}
                        variant="outline"
                        className="close_wo_button mr-3"
                        // variant='outline-secondary'
                        onClick={() => setShowDeleteConfirmModal(true)}>
                        Delete
                      </Button>
                    )}

                    {canApproveRejectWO && isInReview && (
                      <Button
                        // className={'save-btn font-regular ml-3'}
                        className="save_button_brand mr-2"
                        variant="primary"
                        disabled={loading}
                        onClick={() => handleApproveModal()}>
                        Approve
                      </Button>
                    )}
                    {canApproveRejectWO && isInReview && (
                      <Button
                        variant="outline"
                        className="close_wo_button mr-3"
                        disabled={loading}
                        onClick={() => hadnleRejectModal()}>
                        Reject
                      </Button>
                    )}
                  </Col>
                </Row>
              </Card.Header>

              {canViewWO && (
                <Card.Body className={'p-0'}>
                  <Row>
                    <Col xs={12}>
                      <div>
                        <Row
                          style={{ padding: '2rem 2rem 0rem 2rem' }}
                          className={' details-filter'}>
                          <Col xs={6}>
                            <h6 className="mb-0"> Work Order Details</h6>
                          </Col>
                          <Col xs={6} className="text-right">
                            <Button
                              variant="outline"
                              onClick={() => setShowModal(true)}
                              className="mb-3 comments_btn_brand"
                              disabled={!workOrder?.extraComments?.length}>
                              View Comments
                              <div
                                className={`comments-button-count ${
                                  !workOrder?.extraComments?.length
                                    ? 'disabled-button-cnt'
                                    : 'enabled-button-cnt'
                                }`}>
                                {!workOrder?.extraComments?.length ? 0 : 1}
                              </div>
                            </Button>
                          </Col>
                          <Col xs={12} sm={12} md={12} lg={8.5} xl={8} className={'mt-4'}>
                            <Row className={'mb-4 filter'}>
                              {isPRO && (
                                <Col xs={3} md={3} sm={3}>
                                  <Form.Group>
                                    <Form.Label className="label_wodetails">Brand</Form.Label>
                                    <p className="field_wodetails">
                                      {
                                        clients?.find(
                                          (client) => client.id === workOrder.fromCustomerId
                                        )?.name
                                      }
                                    </p>
                                  </Form.Group>
                                </Col>
                              )}
                              <Col xs={3} md={3} sm={3}>
                                <Form.Group>
                                  <Form.Label className="label_wodetails">Entity Type</Form.Label>
                                  <p className="field_wodetails">{workOrder?.entityType || '-'}</p>
                                </Form.Group>
                              </Col>
                              <Col xs={3} md={3} sm={3}>
                                <Form.Group>
                                  <Form.Label className="label_wodetails">
                                    CPCB Wallet Number
                                  </Form.Label>
                                  <p className="field_wodetails">
                                    {workOrder?.cpcbWalletNumber || '-'}
                                  </p>
                                </Form.Group>
                              </Col>
                              <Col xs={3} md={3} sm={3}>
                                <Form.Group>
                                  <Form.Label className="label_wodetails">
                                    Traceability Documents
                                  </Form.Label>
                                  <p className="field_wodetails">
                                    {workOrder?.traceabilityDocuments === true ? 'Yes' : 'No'}
                                  </p>
                                </Form.Group>
                              </Col>
                              <Col xs={3} md={3} sm={3}>
                                <Form.Group>
                                  <Form.Label className="label_wodetails">Start date</Form.Label>
                                  <p className="field_wodetails">
                                    {toLocalDate(workOrder?.fromDate)}
                                  </p>
                                </Form.Group>
                              </Col>
                              <Col xs={3} md={3} sm={3}>
                                <Form.Group>
                                  <Form.Label className="label_wodetails">
                                    Estimated delivery date
                                  </Form.Label>
                                  <p className="field_wodetails">
                                    {toLocalDate(workOrder?.toDate)}
                                  </p>
                                </Form.Group>
                              </Col>

                              <Col xs={3} md={3} sm={3}>
                                <Form.Group>
                                  <Form.Label className="label_wodetails">PO Date</Form.Label>
                                  <p className="field_wodetails">
                                    {toLocalDate(workOrder?.poDate) || '-'}
                                  </p>
                                </Form.Group>
                              </Col>
                              <Col xs={3} md={3} sm={3}>
                                <Form.Group>
                                  <Form.Label className="label_wodetails">PO Number</Form.Label>
                                  <p className="field_wodetails">
                                    {workOrder?.poDetails && workOrder?.poDetails.length
                                      ? workOrder?.poDetails
                                          ?.map((poDetail) => {
                                            return poDetail.purchaseOrderNumber;
                                          })
                                          .join(', ')
                                      : workOrder?.poNumber || '-'}
                                  </p>
                                </Form.Group>
                              </Col>

                              <Col xs={3} md={3} sm={3}>
                                <Form.Group>
                                  <Form.Label className="label_wodetails ">
                                    Payment Term(Days){' '}
                                  </Form.Label>
                                  <p className="field_wodetails">{workOrder?.paymentTerm || '-'}</p>
                                </Form.Group>
                              </Col>

                              <Col xs={3} md={3} sm={3}>
                                <Form.Group>
                                  <Form.Label className="label_wodetails">
                                    Fulfillment Year
                                  </Form.Label>
                                  <p className="field_wodetails">
                                    {`${toFinYear(
                                      workOrder?.fulfillmentYearStartDate,
                                      workOrder?.fulfillmentYearEndDate
                                    )}`}
                                  </p>
                                </Form.Group>
                              </Col>

                              <Col xs={3} md={3} sm={3}>
                                <Form.Group>
                                  <Form.Label className="label_wodetails">
                                    Fulfillment Cycle
                                  </Form.Label>
                                  <p className="field_wodetails">
                                    {workOrder?.workOrderItems?.[0]?.fulfillmentCycle || '-'}
                                  </p>
                                </Form.Group>
                              </Col>
                              <Col xs={5} md={5} sm={5}>
                                <Form.Group>
                                  <Form.Label className="label_wodetails">
                                    Additional Invoicing requirements
                                  </Form.Label>
                                  <p className="field_wodetails">
                                    {workOrder?.isInvoicing ? 'Yes' : 'No'}
                                  </p>
                                </Form.Group>
                              </Col>
                              {workOrder?.isInvoicing && (
                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                  <Form.Group>
                                    <Form.Label className="label_wodetails">
                                      Invoicing Comments
                                    </Form.Label>
                                    <p className="field_wodetails_rejection_comments">
                                      {workOrder?.comments?.trim() ?? '-'}
                                    </p>
                                  </Form.Group>
                                </Col>
                              )}

                              {isRejected && (
                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                  <Form.Group>
                                    <Form.Label
                                      style={{ color: '#D92D20' }}
                                      className="label_wodetails">
                                      Rejection Reason
                                    </Form.Label>
                                    <p className="field_wodetails_rejection_comments">
                                      {workOrder?.rejectionComments?.trim() ?? '-'}
                                    </p>
                                  </Form.Group>
                                </Col>
                              )}
                            </Row>
                          </Col>
                          <Col xs={12} md={12} sm={12} lg={3.5} xl={4}>
                            {/* <UploadScrollableCard isEdit={false} documents={workOrder?.documents} /> */}
                            <MultiUploadCard
                              isEdit={false}
                              handleDocChange={handleDocChange}
                              documents={workOrder?.documents}
                              deletDoc={doDeleteDocuments}
                              status={workOrder?.status}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xs={12}>
                      <Row style={{ padding: '0rem 2rem' }} className={'details-filter'}>
                        <Col xs={12}>
                          <h6 className="mb-0" style={{ fontFamily: 'Inter' }}>
                            Targets and deliveries
                          </h6>
                        </Col>
                        <Col xs={12} className={'pt-3'}>
                          {workOrder?.fromCustomerId ? (
                            <WorkOrderItems
                              isWorkOrderOutgoing={isWorkOrderOutgoing}
                              columns={
                                isWorkOrderOutgoing
                                  ? isUserServiceProvider
                                    ? WORK_ORDER_TABLE_COLUMNS.WORK_ORDER_ITEMS_FOR_SP
                                    : WORK_ORDER_TABLE_COLUMNS.WORK_ORDER_ITEMS
                                  : WORK_ORDER_TABLE_COLUMNS.WORK_ORDER_ITEMS_INCOMING
                              }
                              isUserServiceProvider={isUserServiceProvider}
                              browseNodes={browseNodes}
                              onSubRowShow={fetchDeliveries}
                              account={account}
                              regions={region}
                              data={workOrder?.workOrderItems}
                              isHUL={
                                account?.user?.profile?.id === 9053 ||
                                workOrder?.fromCustomerId === 9053
                              }
                              status={workOrder?.status}
                            />
                          ) : (
                            <Loader />
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {isUserRapidue && showHistory && (
                    <div
                      className={'m-5 details-filter'}
                      style={{
                        width: '93%',
                        height: '40%',
                        overflow: 'auto',
                      }}>
                      <WorkOrderChangeLog
                        logs={logs}
                        userList={users}
                        browseNodes={browseNodes}
                        workOrder={workOrder}
                        showModal={showHistory}
                        handleClose={() => setShowHistory(false)}
                        isWorkOrderIncoming={true}
                      />
                    </div>
                  )}
                </Card.Body>
              )}
            </Form>
          </Card>
          <PromptModal
            singleButton={false}
            show={showDeleteConfirmModal}
            title="Delete Work order"
            text="Are you sure you want to delete work order ?"
            onDismiss={() => setShowDeleteConfirmModal(false)}
            onSuccess={() => doDeleteWorkOrder()}
            onHide={() => setShowDeleteConfirmModal(false)}
          />
          <DocumentModal
            isAPICallForImageView={true}
            displayDocumentModal={displayDocumentModal}
            documents={workOrder.documents}
            handleDocChange={handleDocChange}
            deletDoc={doDeleteDocuments}
            canUpdate={isWorkOrderOutgoing}
            setDisplayDocumentModal={() => setDisplayDocumentModal(false)}
            groupName={isWorkOrderOutgoing ? 'Manage_Vendors' : 'Execute_Targets'}
            moduleName={'Work_Orders'}
          />
          {closeWoModal && (
            <PromptInputModal
              open={closeWoModal}
              title="Close work order?"
              heading="Specify Reason"
              placeholder="Write the reason here"
              buttonText="Yes, Close"
              onHide={() => handleCloseWoModal(false)}
              data={closewomodaltext}
              setData={setClosewomodaltext}
              onSubmit={submitclosewodetails}
              danger={true}
            />
          )}

          <RejectWorkOrderModal
            isOpen={showRejectModal}
            hideModal={hadnleRejectModal}
            rejectionComments={rejectionComments}
            setRejectionComments={setRejectionComments}
            loading={loading}
            approveRejectHandler={approveRejectHandler}
          />

          <ApproveWorkOrderModal
            isOpen={showApproveModal}
            hideModal={handleApproveModal}
            approvalComments={approvalComments}
            setApprovalComments={setApprovalComments}
            loading={loading}
            approveRejectHandler={approveRejectHandler}
          />

          <PromptModal
            show={openWoModal}
            text={'Are you sure you want to open the work order?'}
            subText={
              'Note: Once the work order is opend the service provider will be able to assign any deliveries to the work order'
            }
            dismissBtnText={'No'}
            successBtnText={'Open work order'}
            onDismiss={() => setOpenWoModal(false)}
            onSuccess={() => {
              setCloseWoModal(false);
              setClosewomodaltext('');
              doOpenCloseWorkOrders('OPEN');
            }}
            onHide={() => setOpenWoModal(false)}
            size={'md'}
            title={'Open Work Order'}
          />
          <CommentsModal
            modalShow={showModal}
            handleClose={handleClose}
            workOrder={workOrder}
            mode={'view'}
          />

          {specialModal && (
            <PromptInputModal
              open={specialModal}
              title="Special Approving Work Order?"
              heading="Specify Reason"
              placeholder="Write the reason here"
              buttonText="Save Changes"
              onHide={() => setSpecialModal(false)}
              data={specialData}
              setData={setSpecialData}
              onSubmit={ModalsavedEditedDetails}
            />
          )}
        </React.Suspense>
      )}
    </div>
  );
};

export default BrandWorkOrderDetails;

const EventManager = (function () {
  let instance;

  function createInstance() {
    var object = {
      subscribers: {},
      unhandledPublishes: [],
    };
    return object;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export const subscribe = (handle, callback) => {
  let eventManager = EventManager.getInstance();
  eventManager.subscribers[handle] = callback;

  // This is to allow actions to be taken on elements which are not currently mounted.
  // When the components do get mounted (and if they do subscribe), the event is replayed.
  for (let unhandledPublish of eventManager.unhandledPublishes) {
    if (unhandledPublish.handle === handle) {
      publish(unhandledPublish.handle, unhandledPublish.event, unhandledPublish.payload);
    }
  }
};

export const publish = (handle, event, payload) => {
  let eventManager = EventManager.getInstance();
  if (eventManager.subscribers[handle]) {
    eventManager.subscribers[handle](event, payload);
  } else {
    eventManager.unhandledPublishes.push({ handle, event, payload });
  }
};

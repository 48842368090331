import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import FormControl from 'react-bootstrap/FormControl';
import { RcylDatePicker } from '../../../shared/Components';
import {
  EXECUTION_MODE,
  FULFILLMENT_CYCLES,
  UNIT_TYPES,
  WORK_ORDER_STATUS,
} from '../../../shared/Constants/Constants';
import { toLocalDate } from '../../../shared/Utils/Helper';
import { getUnallocated } from '../../../state/Execution/selectors';
import './TabelCells.scss';

export function DropDownCell(props) {
  const [dropDownVal, setDropDownValue] = useState(props?.cellInfo?.cell?.value);

  useEffect(() => {
    if (props?.cellInfo?.cell?.value && dropDownVal !== props?.cellInfo?.cell?.value) {
      setDropDownValue(props?.cellInfo?.cell?.value);
    }
  }, [props?.cellInfo?.cell?.value]);

  const handleChange = (e) => {
    setDropDownValue(e.target.value);
    props.updateHandler(props?.cellInfo?.row?.index, props?.cellInfo?.column?.id, e.target.value);
  };

  return (
    <div className={'drop-down-cell'}>
      <Form.Group className={'m-0'}>
        <Form.Control
          as={'select'}
          disabled={props?.disabled}
          id={props?.cellInfo?.row?.index + '_' + props?.cellInfo?.column?.id}
          value={dropDownVal !== null ? dropDownVal : ''}
          required={props?.required}
          onChange={handleChange}>
          <option value={''} key={props?.controlName + '_NONE'}>
            Select option
          </option>
          {props.list
            ? Array.isArray(props.list)
              ? props?.list?.map((listItem) => {
                  return (
                    <option
                      key={props?.controlName + '_' + listItem?.value}
                      value={listItem?.value}>
                      {listItem?.label}
                    </option>
                  );
                })
              : Object.keys(props.list)
                  .sort()
                  .map((key) => {
                    return (
                      <optgroup label={key}>
                        {props?.list[key].map((opt) => {
                          return (
                            <option key={props?.controlName + '_' + opt?.value} value={opt?.value}>
                              {opt?.label}
                            </option>
                          );
                        })}
                      </optgroup>
                    );
                  })
            : null}
        </Form.Control>
      </Form.Group>
    </div>
  );
}

export function InputCell(props) {
  const [inputVal, setInputValue] = useState(props?.cellInfo?.cell?.value || props?.value);

  useEffect(() => {
    if (props?.cellInfo?.cell?.value && inputVal !== props?.cellInfo?.cell?.value) {
      setInputValue(props?.cellInfo?.cell?.value);
    }
  }, [props?.cellInfo?.cell?.value]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const updateHandler = (e) => {
    if (props?.cellInfo?.cell?.value !== inputVal)
      props.updateHandler(
        props?.cellInfo?.row?.index,
        props?.cellInfo?.column?.id,
        e?.target?.value,
        props?.cellInfo
      );
  };

  return (
    <div className={'input-cell'}>
      <Form.Group
        controlId={props?.cellInfo?.row?.index + '_' + props?.cellInfo?.column?.id}
        className={'m-0'}>
        <FormControl
          type={props?.inputType}
          value={inputVal ? inputVal : ''}
          required={props?.required}
          placeholder={props?.placeholder}
          min={props.min}
          max={props.max}
          step={props.step}
          disabled={props.disabled}
          onBlur={updateHandler}
          onChange={handleChange}
          isInvalid={props?.isInvalid}
          onKeyDown={props.handleKeyDown}></FormControl>
      </Form.Group>
    </div>
  );
}

export function InputDateCell(props) {
  const [inputVal, setInputValue] = useState(props?.cellInfo?.cell?.value);

  useEffect(() => {
    if (props?.cellInfo?.cell?.value && inputVal !== props?.cellInfo?.cell?.value) {
      setInputValue(props?.cellInfo?.cell?.value);
    }
  }, [props?.cellInfo?.cell?.value]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <div>
      <RcylDatePicker
        minDate={props.min}
        maxDate={props.max}
        // parentEl="#deliveryDate"
        singleDatePicker
        disabled={props?.disabled}
        startDate={inputVal}
        inputProps={{ size: 'sm' }}
        isInvalid={props.isInvalid || false}
        onApply={({ startDate, endDate }, $event) => {
          setInputValue(startDate);
          props.updateHandler(props?.cellInfo?.row?.index, props?.cellInfo?.column?.id, startDate);
        }}
        drops="up"
      />
    </div>
  );
}

export function MaterialCategoryCell(props) {
  return <span>{props?.browseNodes?.find((node) => node?.id === props?.browseNodeId)?.title}</span>;
}

export function MaterialCell(props) {
  const browseNodeId = props?.materialId === 'RPPW007' ? '2' : props?.browseNodeId;
  return (
    <span>
      {
        props?.browseNodes
          ?.find((node) => node?.id === browseNodeId)
          ?.items?.find((item) => item?.id === props.materialId)?.name
      }
    </span>
  );
}

export function HsnSacCodeCell({ cellInfo, isSac }) {
  const itemId = cellInfo?.data?.find(
    (item) => item?.itemId === cellInfo?.cell?.row?.original?.itemId
  );
  return <span>{isSac ? itemId?.sacCode : itemId?.hsnCode}</span>;
}

export function MaterialUnitCell(props) {
  return (
    <span className={'text-capitalize'}>
      {props?.browseNodes
        ?.find((node) => node.id === props?.browseNodeId)
        ?.items?.find((item) => item.id === props.materialId)
        ?.unitType?.toLowerCase()}
    </span>
  );
}

export function StateCell(props) {
  return <span>{props?.regions?.find((state) => state.id === props?.stateId)?.name}</span>;
}

export function DistrictCell(props) {
  return (
    <span>
      {
        props?.regions
          ?.find((state) => state.id === props?.stateId)
          ?.districts?.find((district) => district.id === props.districtId)?.name
      }
    </span>
  );
}

export function FulfillmentCycleCell(props) {
  return (
    <span>
      {FULFILLMENT_CYCLES.find((cycle) => cycle.value === props?.fulfillmentCycleId)?.label}
    </span>
  );
}

export function FormattedDateCell(props) {
  return <span>{toLocalDate(props?.date, props?.format)}</span>;
}

export function ExecutionModeCell(props) {
  return (
    <span>{EXECUTION_MODE?.find((cycle) => cycle.value === props?.executionMode)?.label}</span>
  );
}

export function ServiceProviderCell(props) {
  return (
    <span>
      {props?.serviceProviders?.find((provider) => provider.id === props?.serviceProviderId)?.name}
    </span>
  );
}

export function BrandCell(props) {
  return <span>{props?.brands?.find((brand) => brand.id === props?.brandId)?.name}</span>;
}

export const ClientCell = React.memo((props) => {
  return (
    <span>
      {props?.clientId === props.loggedInUserId || !props.clientId
        ? 'Internal'
        : props?.clients?.find((client) => client.id === props?.clientId)?.name}
    </span>
  );
});

export function WorkOrderStatusCell(props) {
  return <span>{WORK_ORDER_STATUS.find((status) => status.id === props?.statusId)?.label}</span>;
}

export function ColumnSort(props) {
  return (
    <div className="icons-section">
      <div className={'column-sort ml-3'}>
        <FontAwesomeIcon
          icon={faCaretUp}
          className={
            props?.color || (props?.column?.isSorted && props?.column?.isSortedDesc) ? 'active' : ''
          }
        />
      </div>
      <div className="margins-t4 ml-3 column-sort">
        <FontAwesomeIcon
          icon={faCaretDown}
          className={
            props?.color || (props?.column?.isSorted && !props?.column?.isSortedDesc)
              ? 'active'
              : ''
          }
        />
      </div>
    </div>
  );
}

export const UnallocatedQtyCell = React.memo(function (props) {
  const [qty, setQty] = useState(null);
  useEffect(() => {
    if (props.browseNodeId && props.itemId) {
      const unallocatedQty = getUnallocated(
        props.orderSummary,
        props?.account?.user?.profile?.id,
        props?.itemId,
        props?.stateId,
        props?.districtId,
        props?.target,
        props?.isBrand
      );
      setQty(unallocatedQty);
    }
  }, [props.browseNodeId, props.itemId, props.stateId, props.districtId, props.clientId]);

  return (
    <div className={'d-inline-flex  align-items-center ml-5'}>
      {qty || 0} &nbsp;{UNIT_TYPES.KG?.displayText}
    </div>
  );
});

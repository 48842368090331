import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';
import clevertap from 'clevertap-web-sdk';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import PushNotification from './shared/Config/push-notification';
import { serviceWorkerInit, serviceWorkerUpdate } from './state/User/userSlice';
import store from './store';
import Theme from './theme/Theme';

clevertap.init('TEST-58Z-966-8K7Z');
clevertap.spa = true;

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <Provider store={store}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <App />
        </GoogleOAuthProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onSuccess: () => store.dispatch(serviceWorkerInit()),
  onUpdate: (registration) => store.dispatch(serviceWorkerUpdate(registration)),
});
PushNotification.init();

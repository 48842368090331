import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

export default function TooltipOnHover({ msg, children, dataStyle }) {
  return (
    <OverlayTrigger
      key={'top'}
      placement={'top'}
      overlay={
        <Popover
          id="popover-positioned-top"
          className="p-1"
          style={{
            color: '#fff',
            backgroundColor: '#0C111D',
          }}>
          {msg && msg.length > 80 ? `${msg.slice(0, 80)}...` : msg ?? 'NA'}
        </Popover>
      }>
      <div style={dataStyle}>{children}</div>
    </OverlayTrigger>
  );
}
